
import { Route, Router } from '@angular/router';
import { CommonService } from './../../services/commons/commons.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import User from 'src/app/models/user';
import { DataSessionService } from 'src/app/services/commons/data-session.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent  implements OnInit, OnDestroy {
  id:string="";
  subs:Subscription[] = [];
  loggedUser:User|null = new User();
  goldValue:number = 0; //valore in euro al grammo
  timerIsExpired:boolean = true;
  hour:number = 0;
  minutes:number = 20;
  seconds:number = 0;
  intervalHandler:any;

  get timer():string{
    let seconds = this.seconds;
    let secondsString = seconds<10?"0"+seconds.toString():seconds.toString();
    return this.hour.toString()+":"+ (this.minutes<9? "0"+this.minutes.toString():this.minutes.toString())+":"+secondsString;
  }

  constructor(
    private dataSessionService:DataSessionService, 
    private commonService:CommonService,
    private router: Router) { 

    }
  
  ngOnDestroy(): void {
    this.subs.forEach((s)=>{
      clearInterval(this.intervalHandler);
      s.unsubscribe();
    });
  }
  ngOnInit() {
    this.loggedUser = this.dataSessionService.loggedUser;
    let s = this.dataSessionService.goldValue$.subscribe(
      (resp)=>{
        this.goldValue = resp as number;
      }
    )
  }
  onComponentIsActive(){
    //to override
    this.loggedUser = this.dataSessionService.loggedUser;
    if(this.loggedUser!=null)
      this.onComponentIsReady();
  }
  onComponentIsReady(){
    //to override
  }
  async copyToClipboard(text:string) {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Text copied to clipboard');
      this.commonService.showToast("clipboardToast","Copied",4000,"bottom","success")
    } 
    catch (err) {
        console.error('Failed to copy text: ', err);
      }
  }
  navigateTo(route:string){
    this.router.navigate([route]);
    
  }
  round(number?:number, decimals?:number){
    if(number==undefined || number == null)
      return 0;
    let factor = 10 ** decimals! ; // ** -> operatore per elevamento a potenza
    let rounded =Math.floor(number * factor) / factor;
    return rounded;
  }
  checkTimeIsPassed(dateTime:Date){
    const now = new Date();
    const dateToCheck = new Date(dateTime);

    // Aggiungi 15 minuti alla data di input
    dateToCheck.setMinutes(dateToCheck.getMinutes() + 20);
    dateToCheck.setHours(dateToCheck.getHours() + this.hour);

    return dateToCheck < now;
  }
  startTimerWork(){
    clearInterval(this.intervalHandler);
    this.intervalHandler = setInterval(()=>{
      this.timerWork();
      let minutes = (24 - this.hour)*60
      minutes+= 60 - this.minutes;
     // this.progress = minutes / 1440;
    },1240);
  }
  stopTimerWork(){
    clearInterval(this.intervalHandler);
  }
  timerWork(){
    this.seconds--;
    //console.log(this.seconds);
    if(this.seconds == 0){
      this.seconds = 59;
      this.minutes--;
      if (this.minutes==0){
        this.hour--
        this.minutes = 59;
        if(this.hour == 0)
          this.stopTimerWork();
      }

    }
  }


}

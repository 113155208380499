import { Injectable } from "@angular/core";
import { HttpVerbs, NGHttpService } from "./commons/ng-http.service";
import { AuthService } from "./commons/auth.service";

@Injectable({
  providedIn: 'root'
})
export class GoldService {

  private endPoint: string = '/wallet';


  constructor(
    private readonly ngHttpService: NGHttpService,
    private readonly authService: AuthService,
  ) { }


  async get(): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/pricegold`, null, null, () => this.authService.getToken());
  }

  
 /* async list(params: any = {}): Promise<Array<User>> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}`, params, this.mapToModel, () => this.authService.getToken());
  }

  async get(id: any, params: any={}): Promise<User> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/${id}`, params, this.mapToModel, () => this.authService.getToken());
  }

  async save(model: User): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.POST, this.endPoint, model.toObject(true), this.mapToModel, () => this.authService.getToken());
  }

  async createcredit(body: any) {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/createcredit`, body, null, () => this.authService.getToken());
  }

  async delete(id: any): Promise<boolean> {
    return await this.ngHttpService.execute(HttpVerbs.DELETE, `${this.endPoint}/${id}`, null, null, () => this.authService.getToken());
  }

  async recovery(email: string): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/recoveryPassword/${email}`, null, null);
  }
*/

  /*=======================================================================*/
  /*mapToModel(item: any) {
    let model = User.fromObject(item, new User());
    return model;
  }*/

}

export const environment = {
  production: true,

  /* PROD servers */
  apiBaseUrl: 'https://api.mygoldbank.it',
  //eadGPT: 'https://api.gp-technology.com/setup/getGPT',
  //appUrl: 'https://app.gp-technology.com/'

  // /* DEV servers (VPS di Alessandro) */
  // apiBaseUrl: 'https://gpttech.tboxlab.com:9001',
  // readGPT: 'https://gpttech.tboxlab.com:9001/setup/getGPT',

  // /* LOCAL DEV servers */
  // apiBaseUrl: 'https://localhost:8000',
  // readGPT: 'https://localhost:8000/setup/getGPT',
};

import { Injectable } from '@angular/core';
import { setThrowInvalidWriteToSignalError } from '@angular/core/primitives/signals';
import { BehaviorSubject } from 'rxjs';
import BaseModel from 'src/app/models/base.model';
import User from 'src/app/models/user';
import { GoldService } from '../gold.services';

@Injectable({
  providedIn: 'root'
})
export class DataSessionService {

  constructor(private goldService:GoldService) { 
    let user = sessionStorage.getItem("user");
    if(user!=null){
      this.loggedUser = BaseModel.fromObject(JSON.parse(user),this.loggedUser,false);
      this.loggedUser$.next(this.loggedUser);
    }
    this.init();
  }
  intervalHandler:any;

  public loggedUser:User = new User();
  public IBAN:string = "IT0000 000000000"

  //TODO: rendi subscirvible e aggancia al base component
  public goldValue:number = 75.13;
  public goldValue$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  
  public loggedUser$:  BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  init(){
    clearInterval(this.intervalHandler);
    this.intervalHandler = setInterval(()=>{
      this.work();
    },1000 * 60 * 5 );
  }
  work(){
    if(this.loggedUser!=null)
    this.goldService.get().then(
      (result) =>{
        this.goldValue$.next(result);
      } 
    );
  }
  getGoldValue(){
    this.goldService.get().then(
      (result) =>{
        this.goldValue$.next(result);
      } 
    );
  }
}

import BaseModel from "./base.model";
import { environment } from "src/environments/environment";
import { UsersRoles } from "../constants/user-roles";
import { UserRanks } from "../constants/user-ranks";
import { SubscriptionStatus } from "../constants/subscription-states";

export interface Subscription {
  status: SubscriptionStatus;
  expiresOn: Date|string|null;
}

export default class User extends BaseModel {
  id?: string;
  role?: UsersRoles;
  username?: string|null;
  address?:string|null;
  email?: string|null;
  password?: string|null;
  firstName?: string;
  lastName?: string|null;
  birthDate?: Date|string|null;
  registrationDate?: Date|string|null;
  rank?: UserRanks|null;
  phone?: string|null;
  referralCode?: string|null;
  iban?:string;
  subscription?: Subscription|null;
  balance?: number|null;
  language?: string|null;
  boards: string[]|null=null;
  avatar?: number|null;
  sponsor?: string| null;
  qualified?: number|null;
  subscribers?: number|null;
  circles?: number|null;
  active?: number|null;
  alert?:number;
  level?:string;
  //TO DO: Da aggiungere su User
  
  profileImagePath: string|undefined;
  activeAlert:boolean = false;


  /************************************* */
  cap:string  ="";
  civico:string  ="";
  provincia:string="";
  provinciaId:string="";
  comune:string ="";
  comuneId:string ="";
  
  /************************************** */
  genderId?:string;
  statoNascita?:string;
  comuneNascitaId?:string;
  provinciaNascitaId?:string;
  cf?:string;
  tipoDocumentoId?:string;
  numDocumento?:string;
  rilasciatoDa?:string;
  rilasciatoIl?:Date;
  

  public get profileImageUrl(): string|null {
    if (!!this.profileImagePath === false) {
      return 'assets/images/placeholders/avatar.svg';
    }
    return `${environment.apiBaseUrl}/${this.profileImagePath}`;
  }

  public static override fromObject(srcObj: any, instance: any, skipNull: boolean = true) {
    const model: User = BaseModel.fromObject(srcObj, new User());
    // fix
    return model;
  }
}

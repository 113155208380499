<ion-app>
  <ion-toolbar>
    <ion-img src="assets/icon/MyGoldLogoGold.svg" class="logo"></ion-img>
    <span slot="end" class="normal-case ">
      <ion-label class="gold" >ORO</ion-label> 
        <span [class.red]="!valueUp" [class.green]="valueUp">
          &nbsp;{{goldValue|number:'1.2-2'}} €/Gr
        </span>
    </span>
    <ion-buttons slot="end">
      <ion-button [routerLink]="'/auth/profile'">
        <ion-icon name="person-circle-outline" size="large"></ion-icon>
      </ion-button>
  </ion-buttons>
  </ion-toolbar>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" style="max-width: 270px; --border: 0px;">
      <ion-content style="width: 270px;">
        <ion-list id="inbox-list">        
         
          
          <ion-menu-toggle auto-hide="false" >
            <ion-item routerDirection="root"
                      class="btn-bg" 
                      [class.hidden] = "!p.show"
                      *ngFor="let p of appPages; let i = index"
                      [routerLink]="[p.url]" 
                      lines="none" 
                      detail="false" 
                      routerLinkActive="selected">  
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
            <ion-item routerDirection="root" 
                      class="btn-bg" 
                      (click) = "logout()"
                      lines="none" 
                      detail="false" 
                      routerLinkActive="selected">  
                    <ion-label>Esci</ion-label>
            </ion-item>
          </ion-menu-toggle>

        </ion-list>       
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content" (activate)="onActivate($event)"></ion-router-outlet>
  </ion-split-pane>

  <div style="position: absolute; bottom:0px;right:10px;color:white;font-size: 9px;font-family: 'Bahnschrift';">
    V.{{version}}
  </div>
</ion-app>

<!--

-->
// file-upload.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { FileUploadComponent } from './file-upload.component';

@NgModule({
  declarations: [FileUploadComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    IonicModule, // Necessario per utilizzare componenti Ionic come ion-button
  ],
  exports: [FileUploadComponent] // Esporta il componente per renderlo disponibile in altri moduli
})
export class FileUploadModule {}

import { BaseComponent } from './components/base/base.component';
import { DataSessionService } from 'src/app/services/commons/data-session.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/commons/auth.service';
import { Router } from '@angular/router';
import jsonpack from '../../package.json'
import { CommonService } from './services/commons/commons.service';
import User from './models/user';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit{

  public appPages:any[] = [];
  //goldValue:number = 0;
  valueUp:boolean = true;
  version:string = "";
  constructor( private authServices:AuthService, 
               private _router:Router,
                public dataSessionServ:DataSessionService,
                private commonServices:CommonService) {

      super(dataSessionServ,commonServices,_router)               
  }
  user?:User; 
  override ngOnInit(): void {
    super.ngOnInit();

    this.dataSessionServ.loggedUser$.subscribe(
      (user)=>{
        this.user = user as User;
        this.appPages = [
          { title: 'Amministrazione', url: '/admin', icon: 'shield', show:this.user?.level=='admin'},
          { title: 'Pannello di controllo', url: '/dashboard', icon: 'mail',  show:true },
          { title: 'Mio Deposito', url: '/deposito/v', icon: 'paper-plane',  show:true },
          { title: 'Acquista Lingotti', url: '/lingotti', icon: 'heart',  show:true },
          { title: 'Piano Oro', url: '/piano-oro', icon: 'archive',  show:true },
          { title: 'Movimentazioni', url: '/movimentazioni', icon: 'trash',  show:true },
          { title: 'Website', url: '/website', icon: 'warning',  show:true },
        ];
      });
    this.version = jsonpack.version;
    this.dataSessionServ.goldValue$.subscribe(
      (value:any)=>
      {
        this.valueUp = this.goldValue<value
        this.goldValue = value!=null?value:0;
      }
      
    )
    this.dataSessionServ.getGoldValue();
  }
  
  onActivate(component:any){
    if(component.onComponentIsActive)
       component.onComponentIsActive();
  }

  logout(){
    this.authServices.logout().then(
      r=> 
      {
        this._router.navigate(['/login'])
      }  
    );
  }
}

import { format } from "date-fns";

export default class BaseModel {

    static fromObjectWithSnakeCase(srcObj: any, instance: any) {
      Object.keys(srcObj).forEach((propKeySnakeCase) => {
          const propKey = propKeySnakeCase.replace(/([-_]\w)/g, g => g[1].toUpperCase());
          instance[propKey] = srcObj[propKeySnakeCase];
      });
      return instance;
    }

    static fromObject(srcObj: any, instance: any, skipNull: boolean = true) {
      for (const key of Object.keys(srcObj)) {
        if (skipNull && (srcObj[key] == null || srcObj[key] == undefined)) { continue; }
        if(typeof instance[key] ==='object' && instance[key]?.length == undefined)
          instance[key] = new Date(srcObj[key]);
        else
          instance[key] = srcObj[key];
      }
      return instance;
    }

    toObjectWithSnakeCase(removeNulls: boolean = false) {
        const object: any = {};
        for (const camelCaseKey of Object.keys(this)) {
          if (!!removeNulls === true && !!(this as any)[camelCaseKey] === false) { continue; }
          const snakeCaseKey = camelCaseKey.replace(/[\w]([A-Z])/g, m => `${m[0]}_${m[1]}`).toLowerCase();
          object[snakeCaseKey] = (this as any)[camelCaseKey];
        }
        return object;
    }

    toObject(removeNulls: boolean = false) {
        const object: any = {};
        for (const key of Object.keys(this)) {
            if (!!removeNulls === true && (this as any)[key] === null) { continue; }
            object[key] = (this as any)[key];
        }
        return object;
    }

    protected static getterDate(prop: Date|undefined): string | undefined {
      if (!!prop === false) { return undefined; }
      return format(prop!, 'Y-MM-dd');
    }

    protected static setterDate(value: Date|string) {
      return (value instanceof Date ? value : new Date(Date.parse(value)));
    }

}

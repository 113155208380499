import { inject, NgModule } from '@angular/core';
import { CanMatchFn, PreloadAllModules, Route, Router, RouterModule, Routes, UrlSegment } from '@angular/router';
import { AuthService } from './services/commons/auth.service';

const canMatch: CanMatchFn = async (route: Route, segments: UrlSegment[]) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const loggedUser = authService.loggedUser$.getValue();

  if (!!loggedUser === false) {
    await router.navigate(['/login']);
    return false;
  }

  // Check for admin
  // TODO...

  return true;
};
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./page/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    //canMatch: [canMatch]
  },
  {
    path: 'deposito/:operation',
    loadChildren: () => import('./page/deposito/deposito.module').then( m => m.DepositoPageModule),
    //canMatch: [canMatch]
  },
  {
    path: 'lingotti',
    loadChildren: () => import('./page/lingotti/lingotti.module').then( m => m.LingottiPageModule),
    //canMatch: [canMatch]
  },
  {
    path: 'piano-oro',
    loadChildren: () => import('./page/piano-oro/piano-oro.module').then( m => m.PianoOroPageModule),
    //canMatch: [canMatch]
  },
  {
    path: 'movimentazioni',
    loadChildren: () => import('./page/movimentazioni/movimentazioni.module').then( m => m.MovimentazioniPageModule),
    //canMatch: [canMatch]
  },
  {
    path: 'website',
    loadChildren: () => import('./page/website/website.module').then( m => m.WebsitePageModule),
    //canMatch: [canMatch]
  },
  {
    path: 'login',
    loadChildren: () => import('./page/auth-login/auth-login.module').then( m => m.AuthLoginPageModule)
  },
  {
    path: 'auth/register',
    loadChildren: () => import('./page/auth-register/auth-register.module').then( m => m.AuthRegisterPageModule)
  },
  {
    path: 'auth/verify/:user',
    loadChildren: () => import('./page/auth-validate/auth-validate.module').then( m => m.AuthValidatePageModule)
  },
  {
    path: 'auth/profile',
    loadChildren: () => import('./page/auth-profile/auth-profile.module').then( m => m.AuthProfilePageModule)
  },
  {
    path: 'lingotto-details/:id',
    loadChildren: () => import('./page/lingotto-details/lingotto-details.module').then( m => m.LingottoDetailsPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./page/administrator/administrator.module').then( m => m.AdministratorPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

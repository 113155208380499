import { Router } from "@angular/router";
import { CommonService } from "./commons.service";
import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { HttpVerbs, NGHttpService } from "./ng-http.service";
import { BehaviorSubject } from "rxjs";
import User from "src/app/models/user";
import { StorageKeys } from "src/app/constants/storage-keys";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private endPoint: string = '/auth';

  private defaultUser: User | null = null;

  public loggedUser$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private forcedLogoutInProgress: boolean = false; // To avoid many consecutive calls

  constructor(
    private readonly router: Router,
    private commonService:CommonService,
    private readonly ngHttpService: NGHttpService,
    private readonly storageService: StorageService,
  ) {
    this.loadLoggedUser();
  }

  loadLoggedUser(): void {
    this.ngHttpService.httpErrors$.subscribe(async (errorInfo: any) => {
      if (!!errorInfo === false) { return; }

      // Patch to force logout when token is expired
      if (errorInfo.error.error.statusCode === 401 && !!this.loggedUser$.getValue() && this.forcedLogoutInProgress === false) {
        this.forcedLogoutInProgress = true;
        await this.logout();
        await this.router.navigate(['/']).then(() => { this.forcedLogoutInProgress = false; })
      }
    });

    // Check if is already loaded
    if (!!this.loggedUser$.getValue()) {
      return;
    }

    // Check there is a stored user object, update value to null if not
    const storedUserObject = this.storageService.getObject(StorageKeys.LoggedUser);
    if (!!storedUserObject === false) {
      this.loggedUser$.next(null);
      return;
    }

    // Convert object to model and update value
    const loggedUser: User | null = User.fromObject(storedUserObject, new User());
    loggedUser!.password = '';
    this.loggedUser$.next(loggedUser);
  }

  async setLoginTemp(user: any){
     this.loggedUser$.next(user);
  }


  getToken() {
    return this.storageService.getItem(StorageKeys.AccessToken);
  }

  setNoRegisterAccessValue(value: boolean = false) {
    this.storageService.setItem(StorageKeys.NoRegisterAccess, (value ? '1' : '0'));
  }

  getNoRegisterAccessValue() {
    const value: string | null | undefined = this.storageService.getItem(StorageKeys.NoRegisterAccess);
    return !!value ? !!parseInt(value, 10) : false;
  }

  async login(params: { login: string; password: string; }): Promise<{ tokens: Object; user: User }> {
    const response: any = await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/login`, params);
    if (!!response === false || !!response.error) {
      if(response.message == 'user-not-actived'){
        this.loggedUser$.next(response.data.user);
        this.defaultUser=response.data.user;
      }
      if(response.message == 'address-crypto-user-not-valid'){
        this.commonService.showToast("walletPreparation","We are almost ready! We are creating your wallet! Please try on later!",4000,"bottom","danger");
        this.defaultUser=response.data.user;
      }
      return response;
    }
    response.data.user = this.mapItemToModel(response.data.user);
    this.defaultUser=response.data.user;
    this.loggedUser$.next(response.data.user);
    this.storageService.setItem(StorageKeys.AccessToken, response.data.tokens.access_token);
    this.storageService.setObject(StorageKeys.LoggedUser, response.data.user);
    return response.data;
  }

  async loginTest(params: { id: string; }): Promise<{ user: User }> {
    const response: any = await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/loginTest`, params);
    if (!!response === false || !!response.error) {
      return response;
    }
    response.data.user = this.mapItemToModel(response.data.user);
    this.loggedUser$.next(response.data.user);
    // this.storageService.setItem(StorageKeys.AccessToken, response.data.tokens.access_token);
    // this.storageService.setObject(StorageKeys.LoggedUser, response.data.user);

    return response.data;
  }

  /*async passwordRecovery(email:string): Promise<any> {
    
      const response: any = await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/recoveryPassword/${email}`, null, null, () => this.getToken());
      console.log('SERVER-LOGOUT-RESPONSE -> ', response);
    
  }*/

  async restoreDefaultUser(): Promise<any> {
    console.log(this.defaultUser)
    this.loggedUser$.next(this.defaultUser);
  }

  async logout() {
    if (!!this.loggedUser$.getValue()) {
      const response: any = await this.ngHttpService.execute(HttpVerbs.DELETE, `${this.endPoint}/logout/${this.loggedUser$.getValue()!.id}`, null, null, () => this.getToken());
      console.log('SERVER-LOGOUT-RESPONSE -> ', response);
    }

    this.loggedUser$.next(null);
    this.storageService.removeItem(StorageKeys.LoggedUser);
    this.storageService.removeItem(StorageKeys.AccessToken);
    this.storageService.removeItem(StorageKeys.NoRegisterAccess);
  }

  async register(model: User): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/register`, model.toObject(true), this.mapItemToModel, () => this.getToken());
  }

  async verifyUser(username: string): Promise<any> {
    return await this.ngHttpService.execute(HttpVerbs.GET, `${this.endPoint}/verify/${username}`, null, this.mapItemToModel, () => this.getToken());
  }

  async update(model: User): Promise<any> {
    const response = await this.ngHttpService.execute(HttpVerbs.POST, `${this.endPoint}/update`, model.toObject(true), this.mapItemToModel, () => this.getToken());
    this.loggedUser$.next(response.data);
    this.storageService.setObject(StorageKeys.LoggedUser, response.data);
    return response;
  }

  async delete(id: string): Promise<boolean> {
    try {
      await this.ngHttpService.execute(HttpVerbs.DELETE, `${this.endPoint}/delete/${id}`, null, null, () => this.getToken());
      return true;
    } catch (error: any) {
      console.log('delete error -> ', error);
      return false;
    }
  }

  mapItemToModel(item: any) {
    console.log(item)
    //TODO
    const model: User = User.fromObject(item, new User());
    console.log("USER: ",model)
    return item;
  }

}
